.Login {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  background-color: #ffd51f;
  background-size: cover;
  background-image: url(https://www.marker-test.de/_Resources/Persistent/2/c/6/e/2c6e2311bc7b9624e409af0f4bb9acac213bd32f/linien_e9e9e9_gelbTopHell.svg);
  padding: 20px;

  svg {
    width: 20px;
  }
}
