.Header {
  margin-bottom: 30px;
  height: 60px;
  top: 0 !important;

  ul {
    list-style: none;
    padding: 0;
    margin: 5px 0;

    li {
      display: inline-block;
      margin: 0 5px;
    }
  }
}
